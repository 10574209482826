// import armenia from "../assets/img/armenia.svg";
// import russia from "../assets/img/russia.svg";
// import english from "../assets/img/english.svg";
import { My_personal_details } from "../constants";

const initialState = {
    rus: {
        language_code: "RU",
        // language_img:russia,
        languages: [
            {
                id: 1,
                language: "Русский",
                type: "TRANSLATE_RUS",
                // img: russia
            },
            {
                id: 2,
                language: "Английский",
                type: "TRANSLATE_ENG",
                // img: english

            }]
        ,
        pushNotificationLabel: "Push уведомления",
        geoLocationLabel: "Геолокация",
        settingsLabel: "Настройка",
        helpLabel: "Помощь",
        loginLabel: "Вход",
        signInLabel: "Регистрация",
        description: "%Лучшие косметологи; рядом с вами в одном приложении.",//---------
        description_bottom: "Запишитесь %сейчас; или по %предварительной записи; со своего компьютера или c помощью нашего приложения, доступного в ",//-------------
        how_it_works: "Как это работает?",//-------------
        opportunities1: "Проконсультируйтесь с %специалистом; онлайн",//-------------
        opportunities2: "Общайтесь в %письменной форме,; где бы вы ни находились",//-------------
        opportunities3: "Запишитесь %онлайн; в удобное для вас время",//---------------
        intuitive: "Интуитивно понятная,и простая платформа!",//----------
        step1: "Вы %выбираете; специалиста",
        step2: "Консультируетесь со %специалистом;",
        step3: "Записывайтесь %онлайн;",
        step1_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        step2_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        step3_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        appointmentLabel: "Записаться",
        started_now: "Начните уже сейчас!",//-------
        started_now_title: "Активируйте свою учетную запись, выполнив несколько простых шагов. Заполните свой профиль, выберите врача, получите экспертную консультацию",//-----
        our_masters: "Наши мастера",//-------------
        service_from: "Услуги от",//-------------
        all_masters: "Все мастера",//----------
        feedback: "Обратная связь",//-------------
        your_name: "Ваше имя",//------------
        your_phone: "Ваш номер телефона",//--------------
        your_email: "Ваша почта",//-----------
        message: "Сообщение",//-----------
        send: "Отправить",//--------------
        userNameLabel: "Имя пользователя",//login
        confirmCodePageTitleLabel: "Введите код подтверждения",
        confirmMailTextLabel: "На ваш електронный адрес (телефон) было отправлено сообщение с кодом. Введите код ниже.",
        passwordLabel: "Пароль",
        startPageTitleLabel: "Регистрация через адрес электронной почты или номер телефона",
        youForgotLoginOrPasswordLabel: "Вы забыли логин или пароль?",
        restoreLabel: "Восстановить",
        fill_inputs: "Заполните поля",//registration
        confirmLabel: "Подтвердить",
        resendCodeLabel: "Отправить код еще раз",
        registerLoginTitleLabel: "Придумайте логин",
        registerPasswordTitleLabel: "Придумайте пароль",
        confirmPasswordLabel: "Повторите пароль",
        continue: "Продолжить",//----
        click_continue: "Нажимая продолжить Вы принимаете",//------
        termsOfUseLabel: "“Пользовательское соглашение”",
        empty_error: "empty error",
        userNameErrorMessageLabel: "Please enter a valid username",
        error_email: "error email",
        phone_error: "phone error",
        passwordErrorMessageLabel: "Invalid password",
        registerConfirmCodeTitleLabel: "Введите проверочный код",
        registerConfirmCodeInfoLabel: "Мы отправили его на Ваш номер:",
        iForgotAndWantToRememberLabel: "Я забыл и хочу восстановить",//restore
        loginPlaceholderLabel: "Логин",
        passwordLabel: "Пароль",
        recoveryInfoTextLabel: "Что бы восстановить ваш пароль введите ваш телефон или почту, которую вы указали при регистрации",
        phonePlaceholderLabel: "Телефон",
        emailPlaceholderLabel: "Эл.почта",
        confirmMailTextLabel: "На ваш електронный адрес было отправлено сообщение с кодом. Введите код ниже.",
        confirmCodePageTitleLabel: "Введите код",
        continueLabel: "Далее",
        main: "Главная",//----
        onMapLabel: "На карте",
        popularLabel: "Популярные",
        timelineLabel: "Лента работ",
        actualLabel: "Актуальные",//order
        completion: "Завершение", //--------------
        archivalLabel: "Архивные",
        myNotesLabel: "Мои записи",
        no_order: "На данный момент у Вас нет записей",//-------------
        find_masters_order: "Найдите и запишитесь к мастерам, которые Вам подходят",//--------------
        findMasterLabel: "Найти мастера",
        orderStatusAcceptedLabel: "Подтверждено",
        orderStatusDoneLabel: "Выполненно",
        orderStatusPendingLabel: "На подтверждении",
        cancel: "Отменить",//--------------
        orderStatusCancelledLabel: "Oтменен",
        orderStatusPassedLabel: "passed",
        created_at: "Созданный",//-------------
        km: "км",//------
        reviewLabel: "отзывов",
        totalLabel: "Итого:",
        minuteLabel: "минут",
        min: "мин",//---
        pay: "Оплатить",//------
        chatLabel: "Чат",
        delete_message: "Are you sure you want to delete the message?",//--------------
        deleted_message: "The message has been deleted",//=---------------------
        post_review: "Օставить отзыв",//---------
        amount_master: "Сумма выплаченна мастеру",//--------------------
        feedback_cooperation: "Теперь Вы можете оставить свой отзыв о сотрудничестве",//-----------------
        your_feedback: "Ваш отзыв",//----------------
        indicateOrderCancelReasonLabel: "Укажите причину отмены заказа:",
        archive_order: "На данный момент у вас нет архивных записей",//-------------
        masters_suit: "Найдте и запишитесь к мастерам, которые вам подходят",//-------
        not_selected_masters: "На данный момент у вас нет избранных мастеров",// selected_masters-----------
        searchLabel: "Поиск",
        favoriteMasterLabel: "Избранные мастера",
        messagesLabel: "Сообщения",//chat
        addCommentLabel: "Добавить комментарий...",
        no_comment: "У Вас пока нет сообщений",//--------------
        publications_works: "Публикаций работ",//masters_page-------
        completed_orders: "Выполненных заказов",//------
        repeated_calls: "Повторных обращений",//-------------
        postsLabel: "Публикации",
        infoLabel: "Информация",
        servicesLabel: "Услуги",
        reviewsLabel: "Отзывы",
        masters_page: "Страница мастера", //------------
        workScheduleLabel: "График",
        stepLabel: "Шаг",//registers step
        recording_master: "Запись к мастеру", //-----
        chosenLabel: "Выбранный",
        masters_address: "Адрес мастера:",//---------
        client_address: "Client address",
        atHomeLabel: "Выезд на дом",
        takeTimeLabel: "Займет времени:",
        priceLabel: "Стоимость",
        reserve_amount: "Зарезервировать сумму",//-----
        busyLabel: "Занято",
        freeLabel: "Свободно",
        master_response: "Время ожидания ответа мастера",//----
        hour: "часов", //----
        hours: "часа",//---
        additionalCommentsLabel: "Дополнительные комментарии:",
        totalCostLabel: "Итоговая стоимость:",
        prepayment: "Для резервирования Вам необходимо оплатить предоплаты",//-----
        reservationAmountLabel: "Резервирование суммы",
        selectPaymentMethodLabel: "Выберите способ оплаты",
        makePaymentLabel: "Внести платеж",
        thanksLabel: "Спасибо!",
        waitingMasterResponseLabel: "Ожидайте подтверждение мастера",
        signed_up_services: "Вы записались на услуги:",//----
        writeToMasterLabel: "Написать мастеру",
        home_page: "На главную страницу", //-----------
        personal_area: "Личный кабинет", //my profile------------
        nameLabel: "Имя",
        surnameLabel: "Фамилия",
        genderLabel: "Пол",
        birthDayLabel: "Дата рождения",
        yourCityLabel: "Ваш город проживания",
        yourCountryLabel: "Ваш страна проживания",
        emailAddressLabel: "Эл. адрес",
        phoneNumberLabel: "Номер телефона",
        residenceAddressLabel: "Адрес проживания",
        postalCodeLabel: "Почтовый индекс",
        changePhotoLabel: "Сменить фото",
        logoutLabel: "Выйти",
        saveLabel: "Сохранить",
        paymentCardLabel: "Карты оплаты", //cards
        deleteLabel: "Delete",
        select_card: "Выберите карту",//----------
        attached_cards_payment: "У Вас нет привязанных карт для оплаты",//------------
        add_card_application: "Добавьте карту для быстрой оплаты услуг через приложение",//----------------
        addCardLabel: "Добавить карту",
        otherPaymentMethodsLabel: "Другие способы оплаты",
        operationHistoryLabel: "История операций",
        addBankCardLabel: "Добавить банковскую карту",
        cardsLabel: "Карты",
        cardNumberLabel: "Номер карты",
        invalid_card_number: "invalid card number",//-----------
        invalid_month: "invalid month",//---------------
        invalid_year: "invalid year",//--------------------
        invalid_CVV2: "invalid CVV2",//-----------------
        month: "Mесяц",//--------------
        year: "Год",//-------------
        cardValidityPeriodLabel: "Срок действия",
        cardCVVCodeLabel: "Код CVV2",
        cardHolderNameLabel: "ФИО владельца карты",
        cardInvoicingEmailLabel: "E-mail для выставления счёта",
        cardActionInfoTextLabel: "С Вашей карты может быть списана небольшая сумма для подтверждения данных Вашей карты. Возврат будет произведен моментально.",
        login_stripe: "login stripe",
        whatServiceAreYouLookingForLabel: "Какую услугу Вы ищете?", // service
        today: "Сегодня", //----------------
        yesterday: "Вчера",//-----------
        earlier: "Ранее",//------------
        want_hide: "Хотите cкрыть?", //posts------------
        yes: "Да",//---------
        no: "Нет",//---------------
        cause: "Причина",//----------
        complain: "Пожаловаться",//--------
        would_like_complain: "Хотите пожаловаться?",//--------------
        copy_link: "Копировать ссылку",
        successfully_copied: "успешно скопирована",
        share_in: "Поделится в...",
        hide: "Скрыть",
        viewAllCommentsLabel: "Посмотреть все комментарии",
        viewCountLabel: "просмотра",
        answer: "Ответить", //-------
        answers: "ответа",//-------------
        google_map: "Карта",   //map----------
        listLabel: "Списком",
        empty_list: "Список пуст", //-----
        filterLabel: "Фильтр",
        sortByLabel: "Сортировать по",
        popularLabel: "Популярные",
        ratingLabel: "Рейтинг",
        masterLoginAndNameLabel: "Логин или имя мастера",
        placeLocationLabel: "Место расположения",
        searchRadiusLabel: "Радиус поиска",
        serviceTypeLabel: "Вид услуги",
        chanceAtHomeLabel: "Возможность выезда на дом:",
        resetLabel: "Сбросить",
        readyLabel: "Готово",
        your_place_position: "Ваше место положение",//----------
        locate_your_location: "Чтобы увидеть объявления поблизости, разрешите определить ваше местоположение",//------------
        select_city: "Выбор города",//----------------
        serviceSelectionLabel: "Выбор услуг",
        searchRadiusLabel: "Радиус поиска",
        agree_error: "Пожалуйста, нажмите",//-------------
        is_deleted: "был удален",//-----------
        posts_title: "Posts", // meta data
        posts_descriptions: "Posts",
        chats_title: "Chats",
        chats_descriptions: "Chats",
        help_title: "Help",
        help_descriptions: "Help",
        map_title: "Map",
        map_descriptions: "Map",
        schedule_title: "Schedule",
        schedule_descriptions: "Schedule",
        master_title: "My masters",
        master_descriptions: "My masters",
        service_title: "Service",
        service_descriptions: "Service",
        settings_title: "Settings",
        settings_descriptions: "Settings",
        masters_title: "Masters",
        masters_descriptions: "Masters",
        amount_title: "Amount reservation",
        amount_descriptions: "Amount reservation",
        register_master_title: "Register master",
        register_master_descriptions: "Register master",
        register_end_title: "Registration end",
        register_end_descriptions: "Registration end",
        order_title: "My orders",
        order_descriptions: "My orders",
        review_title: "Review",
        review_descriptions: "Review",
        add_card_title: "Add card",
        add_card_descriptions: "Add card",
        payment_card_title: "Payment cards",
        payment_card_descriptions: "Payment cards",
        operations_histroy_title: "Operations histroy",
        operations_histroy_descriptions: "Operations histroy",
        LOGIN_INC_US_PASS: "Identifiant ou mot de passe ne correspondent pas...FR",//errors translate
        LOGIN_INACTIVE_OR_DELETED: "Login inactive or deleted...FR",
        "username already in use": "username already in use...FR",
        "user with this phone number already exists": "user with this phone number already exists...FR",
        "not found": "not found...FR",
        "Not found": "Not found.FR",
        "No user with this phone number registered.": "No user with this phone number registered...FR",
        "No user with this email registered.": "No user with this email registered...FR",
        "No user with this email.": "No user with this email...FR",
        "You have ordered same service for this date.": "You have ordered same service for this date....FR",
        "You have ordered same service which has no final status.": "You have ordered same service which has no final status....FR",
        "Day is non working day": "Day is non working day.FR",
        "No available time for this service in this day.": "No available time for this service in this day....FR",
        process_successful: "The process was successful...FR",
        signup: "Записаться",
    },
    eng: {
        language_code: "ENG",
        // language_img:english,
        languages: [
            {
                id: 1,
                language: "Russian",
                type: "TRANSLATE_RUS",
                // img: russia
            },
            {
                id: 2,
                language: "English",
                type: "TRANSLATE_ENG",
                // img: english

            }],
        prepaymentLabel: "Prepayment.eng.eng",
        pushNotificationLabel: "Push уведомления.eng",
        geoLocationLabel: "Геолокация.eng",
        settingsLabel: "Настройка.eng",
        helpLabel: "Помощь.eng",
        loginLabel: "Вход.eng",
        signInLabel: "Регистрация.eng",
        description: "%Лучшие косметологи; рядом с вами в одном приложении..eng",
        description_bottom: "Запишитесь %сейчас; или по %предварительной записи; со своего компьютера или c помощью нашего приложения, доступного в..eng ",
        how_it_works: "Как это работает?.eng",
        opportunities1: "Проконсультируйтесь с %специалистом; онлайн.eng",
        opportunities2: "Общайтесь в %письменной форме,; где бы вы ни находились.eng",
        opportunities3: "Запишитесь %онлайн; в удобное для вас время.eng",
        intuitive: "Интуитивно понятная,и простая платформа!.eng",
        step1: "Вы %выбираете; специалиста.eng",
        step2: "Консультируетесь со %специалистом;.eng",
        step3: "Записывайтесь %онлайн;",
        step1_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        step2_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        step3_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        appointmentLabel: "Записаться.eng",
        started_now: "Начните уже сейчас!.eng",
        started_now_title: "Активируйте свою учетную запись, выполнив несколько простых шагов. Заполните свой профиль, выберите врача, получите экспертную консультацию.eng",
        our_masters: "Наши мастера.eng",
        service_from: "Услуги от.eng",
        all_masters: "Все мастера.eng",
        feedback: "Обратная связь.eng",
        your_name: "Ваше имя.eng",
        your_phone: "Ваш номер телефона.eng",
        your_email: "Ваша почта.eng",
        message: "Сообщение.eng",
        send: "Отправить.eng",
        userNameLabel: "Имя пользователя.eng",//login
        confirmCodePageTitleLabel: "Введите код подтверждения.eng",
        confirmMailTextLabel: "На ваш електронный адрес (телефон) было отправлено сообщение с кодом. Введите код ниже..eng",
        passwordLabel: "Пароль.eng",
        startPageTitleLabel: "Регистрация через адрес электронной почты или номер телефона.eng",
        youForgotLoginOrPasswordLabel: "Вы забыли логин или пароль?.eng",
        restoreLabel: "Восстановить.eng",
        fill_inputs: "Заполните поля.eng",//registration
        confirmLabel: "Подтвердить.eng",
        resendCodeLabel: "Отправить код еще раз.eng",
        registerLoginTitleLabel: "Придумайте логин.eng",
        registerPasswordTitleLabel: "Придумайте пароль.eng",
        confirmPasswordLabel: "Повторите пароль.eng",
        continue: "Продолжить.eng",
        click_continue: "Нажимая продолжить Вы принимаете.eng",
        termsOfUseLabel: "“Пользовательское соглашение”.eng",
        empty_error: "empty error.eng",
        userNameErrorMessageLabel: "Please enter a valid username",
        error_username: "error username.eng",
        error_email: "error email.eng",
        phone_error: "phone error.eng",
        error_passsword: "error passsword.eng",
        registerConfirmCodeTitleLabel: "Введите проверочный код.eng",
        registerConfirmCodeInfoLabel: "Мы отправили его на Ваш номер:.eng",
        iForgotAndWantToRememberLabel: "Я забыл и хочу восстановить.eng",//restore
        loginPlaceholderLabel: "Логин.eng",
        password_forget: "Пароль.eng",
        recoveryInfoTextLabel: "Что бы восстановить ваш пароль введите ваш телефон или почту, которую вы указали при регистрации.eng",
        phonePlaceholderLabel: "Телефон.eng",
        emailPlaceholderLabel: "Эл.почта.eng",
        confirmMailTextLabel: "На ваш електронный адрес было отправлено сообщение с кодом. Введите код ниже..eng",
        confirmCodePageTitleLabel: "Введите код.eng",
        continueLabel: "Далее.eng",
        main: "Главная.eng",
        onMapLabel: "На карте.eng",
        popularLabel: "Популярные.eng",
        timelineLabel: "Лента работ.eng",
        actualLabel: "Актуальные.eng",//order
        completion: "Завершение.eng", //--------------
        archivalLabel: "Архивные.eng",
        myNotesLabel: "Мои записи.eng",
        no_order: "На данный момент у Вас нет записей.eng",//-------------
        find_masters_order: "Найдите и запишитесь к мастерам, которые Вам подходят.eng",//--------------
        findMasterLabel: "Найти мастера.eng",
        orderStatusAcceptedLabel: "Подтверждено.eng",
        orderStatusDoneLabel: "Выполненно.eng",
        orderStatusPendingLabel: "На подтверждении.eng",
        cancel: "Отменить.eng",//--------------
        orderStatusCancelledLabel: "Oтменен.eng",
        orderStatusPassedLabel: "passed.eng",
        created_at: "Созданный.eng",//-------------
        km: "км.eng",//------
        reviewLabel: "отзывов.eng",
        totalLabel: "Итого:.eng",
        minuteLabel: "минут.eng",
        min: "мин.eng",
        pay: "Оплатить.eng",//------
        chatLabel: "Чат.eng",
        delete_message: "Are you sure you want to delete the message?.eng",//--------------
        deleted_message: "The message has been deleted.eng",//=---------------------
        post_review: "Օставить отзыв.eng",//---------
        amount_master: "Сумма выплаченна мастеру.eng",//--------------------
        feedback_cooperation: "Теперь Вы можете оставить свой отзыв о сотрудничестве.eng",//-----------------
        your_feedback: "Ваш отзыв.eng",//----------------
        indicateOrderCancelReasonLabel: "Укажите причину отмены заказа:.eng",
        archive_order: "На данный момент у вас нет архивных записей.eng",//-------------
        masters_suit: "Найдте и запишитесь к мастерам, которые вам подходят.eng",
        not_selected_masters: "На данный момент у вас нет избранных мастеров.eng",// selected_masters-----------
        searchLabel: "Поиск.eng",
        favoriteMasterLabel: "Избранные мастера.eng",
        messagesLabel: "Сообщения.eng",//chat
        addCommentLabel: "Добавить комментарий....eng",
        no_comment: "У Вас пока нет сообщений.eng",//--------------
        publications_works: "Публикаций работ.eng",//masters_page-------
        completed_orders: "Выполненных заказов.eng",//------
        repeated_calls: "Повторных обращений.eng",//-------------
        postsLabel: "Публикации.eng",
        infoLabel: "Информация..eng",
        servicesLabel: "Услуги..eng",
        reviewsLabel: "Отзывы..eng",
        masters_page: "Страница мастера..eng", //------------
        workScheduleLabel: "График..eng",
        stepLabel: "Шаг..eng",//registers step
        recording_master: "Запись к  мастеру..eng", //-----
        chosenLabel: "Выбранный..eng",
        masters_address: "Адрес мастера:..eng",//---------
        client_address: "Client address",
        atHomeLabel: "Выезд на дом..eng",
        takeTimeLabel: "Займет времени:..eng",
        priceLabel: "Стоимость..eng",
        reserve_amount: "Зарезервировать сумму..eng",//-----
        busyLabel: "Занято..eng",
        freeLabel: "Свободно..eng",
        master_response: "Время ожидания ответа мастера..eng",//----
        hour: "часов..eng", //----
        hours: "часа..eng",//---
        additionalCommentsLabel: "Дополнительные комментарии:..eng",
        totalCostLabel: "Итоговая стоимость:..eng",
        prepayment: "Для резервирования Вам необходимо оплатить предоплаты..eng",//-----
        reservationAmountLabel: "Резервирование суммы..eng",
        selectPaymentMethodLabel: "Выберите способ оплаты..eng",
        makePaymentLabel: "Внести платеж..eng",
        thanksLabel: "Спасибо!..eng",
        waitingMasterResponseLabel: "Ожидайте подтверждение мастера..eng",
        signed_up_services: "Вы записались на услуги:..eng",//----
        writeToMasterLabel: "Написать мастеру..eng",
        home_page: "На главную страницу..eng", //-----------
        personal_area: "Личный кабинет..eng", //my profile------------
        nameLabel: "Имя..eng",
        surnameLabel: "Фамилия..eng",
        genderLabel: "Пол..eng",
        birthDayLabel: "Дата рождения..eng",
        yourCityLabel: "Ваш город проживания..eng",
        yourCountryLabel: "Ваш страна проживания..eng",
        emailAddressLabel: "Эл. адрес..eng",
        phoneNumberLabel: "Номер телефона..eng",
        residenceAddressLabel: "Адрес проживания..eng",
        postalCodeLabel: "Почтовый индекс..eng",
        changePhotoLabel: "Сменить фото..eng",
        logoutLabel: "Выйти..eng",
        saveLabel: "Сохранить..eng",
        paymentCardLabel: "Карты оплаты..eng", //cards
        deleteLabel: "Delete..eng",
        select_card: "Выберите карту..eng",//----------
        attached_cards_payment: "У Вас нет привязанных карт для оплаты..eng",//------------
        add_card_application: "Добавьте карту для быстрой оплаты услуг через приложение..eng",//----------------
        addCardLabel: "Добавить карту..eng",
        otherPaymentMethodsLabel: "Другие способы оплаты..eng",
        operationHistoryLabel: "История операций..eng",
        addBankCardLabel: "Добавить банковскую карту..eng",
        cardsLabel: "Карты..eng",
        cardNumberLabel: "Номер карты..eng",
        invalid_card_number: "invalid card number..eng",//-----------
        invalid_month: "invalid month..eng",//---------------
        invalid_year: "invalid year..eng",//--------------------
        invalid_CVV2: "invalid CVV2..eng",//-----------------
        month: "Mесяц..eng",//--------------
        year: "Год..eng",//-------------
        cardValidityPeriodLabel: "Срок действия..eng",
        cardCVVCodeLabel: "Код CVV2..eng",
        cardHolderNameLabel: "ФИО владельца карты..eng",
        cardInvoicingEmailLabel: "E-mail для выставления счёта..eng",
        cardActionInfoTextLabel: "С Вашей карты может быть списана небольшая сумма для подтверждения данных Вашей карты. Возврат будет произведен моментально...eng",
        login_stripe: "login stripe..eng",
        whatServiceAreYouLookingForLabel: "Какую услугу Вы ищете..eng", // service
        today: "Сегодня..eng", //----------------
        yesterday: "Вчера..eng",//-----------
        earlier: "Ранее..eng",//------------
        want_hide: "Хотите cкрыть?..eng", //posts------------
        yes: "Да..eng",//---------
        no: "Нет..eng",//---------------
        cause: "Причина..eng",//----------
        complain: "Пожаловаться..eng",//--------
        would_like_complain: "Хотите пожаловаться?..eng",//--------------
        copy_link: "Копировать ссылку..eng",
        successfully_copied: "успешно скопирована..eng",
        share_in: "Поделится в.....eng",
        hide: "Скрыть..eng",
        viewAllCommentsLabel: "viewAllCommentsLabel..eng",
        viewCountLabel: "просмотра..eng",
        answer: "Ответить..eng", //-------
        answers: "ответа..eng",//-------------
        google_map: "Карта..eng",   //map----------
        listLabel: "Списком..eng",
        empty_list: "Список пуст", //-----
        filterLabel: "Фильтр..eng",
        sortByLabel: "Сортировать по..eng",
        popularLabel: "Популярные..eng",
        ratingLabel: "Рейтинг..eng",
        masterLoginAndNameLabel: "Логин или имя мастера..eng",
        placeLocationLabel: "Место расположения..eng",
        searchRadiusLabel: "Радиус поиска..eng",
        serviceTypeLabel: "Вид услуги..eng",
        chanceAtHomeLabel: "Возможность выезда на дом:..eng",
        resetLabel: "Сбросить..eng",
        readyLabel: "Готово..eng",
        your_place_position: "Ваше место положение..eng",//----------
        locate_your_location: "Чтобы увидеть объявления поблизости, разрешите определить ваше местоположение..eng",//------------
        select_city: "Выбор города..eng",//----------------
        serviceSelectionLabel: "Выбор услуг..eng",
        searchRadiusLabel: "Радиус поиска..eng",//-----------
        agree_error: "Пожалуйста, нажмите..eng",//-------------
        is_deleted: "был удален..eng",//-----------
        posts_title: "Posts..eng", // meta data
        posts_descriptions: "Posts..eng",
        chats_title: "Chats..eng",
        chats_descriptions: "Chats..eng",
        help_title: "Help..eng",
        help_descriptions: "Help",
        map_title: "Map",
        map_descriptions: "Map..eng",
        schedule_title: "Schedule..eng",
        schedule_descriptions: "Schedule..eng",
        master_title: "My masters..eng",
        master_descriptions: "My masters..eng",
        service_title: "Service..eng",
        service_descriptions: "Service..eng",
        settings_title: "Settings..eng",
        settings_descriptions: "Settings..eng",
        masters_title: "Masters..eng",
        masters_descriptions: "Masters..eng",
        amount_title: "Amount reservation..eng",
        amount_descriptions: "Amount reservation..eng",
        register_master_title: "Register master..eng",
        register_master_descriptions: "Register master..eng",
        register_end_title: "Registration end..eng",
        register_end_descriptions: "Registration end..eng",
        order_title: "My orders..eng",
        order_descriptions: "My orders..eng",
        review_title: "Review..eng",
        review_descriptions: "Review..eng",
        add_card_title: "Add card..eng",
        add_card_descriptions: "Add card..eng",
        payment_card_title: "Payment cards..eng",
        payment_card_descriptions: "Payment cards..eng",
        operations_histroy_title: "Operations histroy..eng",
        operations_histroy_descriptions: "Operations histroy..eng",
        LOGIN_INC_US_PASS: "Identifiant ou mot de passe ne correspondent pas...eng",//errors translate
        LOGIN_INACTIVE_OR_DELETED: "Login inactive or deleted...eng",
        "username already in use": "username already in use...eng",
        "user with this phone number already exists": "user with this phone number already exists...eng",
        "not found": "not found...eng",
        "Not found": "Not found.eng",
        "No user with this phone number registered.": "No user with this phone number registered...eng",
        "No user with this email registered.": "No user with this email registered...eng",
        "No user with this email.": "No user with this email...eng",
        "You have ordered same service for this date.": "You have ordered same service for this date....eng",
        "You have ordered same service which has no final status.": "You have ordered same service which has no final status....eng",
        "Day is non working day": "Day is non working day.eng",
        "No available time for this service in this day.": "No available time for this service in this day....eng",
        "You can not create order for this date range": "You can not create order for this date range....eng",
        process_successful: "The process was successful...eng",
        signup: "Sign up",
    },
    frans: {
        language_code: "FR",
        // language_img:english,
        languages: [
            {
                id: 1,
                language: "Russian",
                type: "TRANSLATE_RUS",
                // img: russia
            },
            {
                id: 2,
                language: "English",
                type: "TRANSLATE_ENG",
                // img: english

            }],
        prepaymentLabel: "Prepayment",
        pushNotificationLabel: "Notifications Push",
        geoLocationLabel: "Géolocalisation",
        settingsLabel: "Paramètres",
        helpLabel: "Aide",
        loginLabel: "Se connecter",
        signInLabel: "Inscription",
        description: "%Meilleures esthéticiennes; près de chez vous dans la même application.",
        description_bottom: "Inscrivez-vous %dès maintenant; depuis %l'ordinateur via ce site web; ou avec notre Application, disponible avec ",
        how_it_works: "Comment ça marche?",
        opportunities1: "Trouvez une %esthéticienne; sur notre site",
        opportunities2: "Contactez la %par messagerie interieur,; pour avoir plus d'informations",
        opportunities3: "Prenez un rendez-vous %en ligne; et gérez les dans votre espace",
        intuitive: "Plateforme intuitive et très simple en utilisation",
        step1: "Вы %выбираете; специалиста.fr",
        step2: "Консультируетесь со %специалистом;.fr",
        step3: "Записывайтесь %онлайн;.fr",
        step1_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..fr",
        step2_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..fr",
        step3_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..fr",
        appointmentLabel: "Réserver",
        started_now: "Commencez dès maintenant!",
        started_now_title: "Inscrivez-vous en 2 minutes chrono. Remplissez votre profil, choissisez un prestataire et prenez un rendez-vous à domicile ou en Institut.",
        our_masters: "Nos esthéticiennes",
        service_from: "Prestations à partir de",
        all_masters: "Toutes les esthéticiennes",
        feedback: "Service client",
        your_name: "Votre Nom",
        your_phone: "Votre téléphone",
        your_email: "Votre e-mail",
        message: "Contacter",
        send: "Envoyer",
        userNameLabel: "Identifiant",//login
        confirmCodePageTitleLabel: "Code de vérification",
        confirmMailTextLabel: "Un code de vérification a été envoyé sur votre e-mail. Veuillez l'entrer ci-dessous.",
        passwordLabel: "Mot de passe",
        startPageTitleLabel: "Inscription avec e-mail ou téléphone",
        youForgotLoginOrPasswordLabel: "Vous avez oublié le mot de passe ou identifiant?",
        restoreLabel: "Récuperer l'accès",
        fill_inputs: "Remplissez les champs",//registration
        confirmLabel: "Confirmer",
        resendCodeLabel: "Renvoyer le code",
        registerLoginTitleLabel: "Votre identifiant",
        registerPasswordTitleLabel: "Votre mot de passe",
        confirmPasswordLabel: "Confirmer les mot de passe",
        continue: "Continuer",
        click_continue: "En cliquant sur Continuer, vous acceptez",
        termsOfUseLabel: "“Conditions gélérales d'utilisation”",
        empty_error: "empty error.fr",
        userNameErrorMessageLabel: "Please enter a valid username.fr",
        error_username: "error username.fr",
        error_email: "error email.fr",
        phone_error: "phone error.fr",
        error_passsword: "error passsword.fr",
        registerConfirmCodeTitleLabel: "Entrez le code de verification",
        registerConfirmCodeInfoLabel: "Il a été envoyé sur votre téléphone:",
        iForgotAndWantToRememberLabel: "Je veux récuperer l'accès",//restore
        loginPlaceholderLabel: "Votre identifiant",
        password_forget: "Пароль...FR",
        recoveryInfoTextLabel: "Pour récuperer l'accès, veuillez introduire votre e-mail ou téléphone.",
        phonePlaceholderLabel: "Téléphone",
        emailPlaceholderLabel: "E-mail",
        confirmMailTextLabel: "Un code de vérification a été envoyé sur votre e-mail. Veuillez l'entrer ci-dessous.",
        confirmCodePageTitleLabel: "Code de vérification",
        continueLabel: "Suite",
        main: "Accueil",
        onMapLabel: "Voir sur la carte",
        popularLabel: "Populaires",
        timelineLabel: "Réalisations",
        actualLabel: "En cours",//order
        completion: "Terminé",
        archivalLabel: "Terminés",
        myNotesLabel: "Réservations",
        no_order: "Vous n'avez aucune réservation actuellement",
        find_masters_order: "Найдите и запишитесь к мастерам, которые Вам подходят...FR",//--------------
        findMasterLabel: "Trouver un prestataire",
        orderStatusAcceptedLabel: "Acceptée",
        orderStatusDoneLabel: "Réalisée",
        orderStatusPendingLabel: "En attente",
        cancel: "Annuler",
        orderStatusCancelledLabel: "Annulée",
        orderStatusPassedLabel: "passed...FR",
        created_at: "Inscrit",
        km: "km",
        reviewLabel: "avis",
        totalLabel: "Montant:",
        minuteLabel: "minutes",
        min: "min",
        pay: "Payer",
        chatLabel: "Contact",
        delete_message: "Voulez vous vraiment supprimer ce message?",
        deleted_message: "Le message a été supprimé",
        post_review: "Laisser un avis",
        amount_master: "Сумма выплаченна мастеру...FR",//--------------------
        feedback_cooperation: "Vous pouvez laissez un avis",
        your_feedback: "Votre avis",
        indicateOrderCancelReasonLabel: "Raison de l'annulation:",
        archive_order: "Vous n'avez pas de réservations",
        masters_suit: "Найдте и запишитесь к мастерам, которые вам подходят...FR",
        not_selected_masters: "На данный момент у вас нет избранных мастеров...FR",// selected_masters-----------
        searchLabel: "Recherche",
        favoriteMasterLabel: "Prestataires favoris",
        messagesLabel: "Conversations",//chat
        addCommentLabel: "Добавить комментарий......FR",
        no_comment: "Vous n'avez pas de conversations",
        publications_works: "Photos de réalisations",//masters_page
        completed_orders: "Préstations réalisées",
        repeated_calls: "Clientes fidelisées",
        postsLabel: "Публикации...FR",//???
        infoLabel: "Информация...FR",//???
        servicesLabel: "Prestations",
        reviewsLabel: "Avis",
        masters_page: "Страница мастера...FR", //------------
        workScheduleLabel: "Mon planning",
        stepLabel: "Etape",//registers step
        recording_master: "Запись к  мастеру...FR", //-----
        chosenLabel: "Ajouté",
        masters_address: "L'adresse de la prestation:",
        client_address: "Client address",
        atHomeLabel: "A domicile",
        takeTimeLabel: "Durée:",
        priceLabel: "Prix",
        reserve_amount: "Зарезервировать сумму...FR",//-----
        busyLabel: "Réservé",
        freeLabel: "Disponible",
        master_response: "Durée de la prestation",
        hour: "heures",
        hours: "heure",
        additionalCommentsLabel: "Informations supplementaires:",
        totalCostLabel: "Montant total:",
        prepayment: "Pour réserver, vous avez besoin de payer un acompte",
        reservationAmountLabel: "Payer l'acompte",
        selectPaymentMethodLabel: "Choisissez un moyen de paiement",
        makePaymentLabel: "Payer l'acompte",
        thanksLabel: "Votre réservation a été bien prise en compte!",
        waitingMasterResponseLabel: "Nous vous remercions pour votre confiance",
        signed_up_services: "Votre réservation:",
        writeToMasterLabel: "Contacter prestataire",
        home_page: "Acceuil",
        personal_area: "Mon compte", //my profile
        nameLabel: "Prénom",
        surnameLabel: "Nom",
        genderLabel: "Sexe",
        birthDayLabel: "Date de naissance",
        yourCityLabel: "Ville",
        yourCountryLabel: "Pays",
        emailAddressLabel: "E-mail",
        phoneNumberLabel: "Téléphone portable",
        residenceAddressLabel: "Adresse",
        postalCodeLabel: "Code postale",
        changePhotoLabel: "Changer photo",
        logoutLabel: "Se deconnecter",
        saveLabel: "Sauvegarder",
        paymentCardLabel: "Cartes de paiement", //cards
        deleteLabel: "Delete...FR", //???
        select_card: "Choisissez la carte",
        attached_cards_payment: "Vous n'avez aucune carte ajoutée",
        add_card_application: "Ajoutez une carte bancaire pour payer rapidement avec l'application",
        addCardLabel: "Ajouter",
        otherPaymentMethodsLabel: "Autres moyens",
        operationHistoryLabel: "Historique",
        addBankCardLabel: "Ajouter une carte bancaire",
        cardsLabel: "Facturation",
        cardNumberLabel: "Numéro de carte",
        invalid_card_number: "Numéro de carte invalide",
        invalid_month: "Mois invalide",
        invalid_year: "Année invalide",
        invalid_CVV2: "Code CVV2 invalide",
        month: "Mois",
        year: "An",
        cardValidityPeriodLabel: "Date d'expiration",
        cardCVVCodeLabel: "CVV2",
        cardHolderNameLabel: "Nom sur la carte",
        cardInvoicingEmailLabel: "Reçu par e-mail",
        cardActionInfoTextLabel: "Il se peut d'un montant minimal soit debité de votre carte. Le remboursement sera immédiat.",
        login_stripe: "login stripe...FR",
        whatServiceAreYouLookingForLabel: "Quelle prestation vous recherchez?", // service
        today: "Aujourd'hui",
        yesterday: "Hier",
        earlier: "Plutôt",
        want_hide: "Cacher ce post?", //posts
        yes: "Oui",
        no: "Non",
        cause: "Raison",
        complain: "Signaler",
        would_like_complain: "Signaler?",
        copy_link: "Копировать ссылку...FR",//???
        successfully_copied: "успешно скопирована...FR",//???
        share_in: "Поделится в......FR",//???
        hide: "Скрыть...FR",//???
        viewAllCommentsLabel: "Посмотреть все комментари...FR",//???
        viewCountLabel: "Aucun avis",
        answer: "Répondre",
        answers: "réponses",
        google_map: "Carte",   //map
        listLabel: "Voir la liste",
        empty_list: "liste est vide",
        filterLabel: "Filtrer",
        sortByLabel: "Trier par",
        popularLabel: "Populaires",
        ratingLabel: "Note",
        masterLoginAndNameLabel: "Login de prestataire",
        placeLocationLabel: "Geolocaliser",
        searchRadiusLabel: "Rayon",
        serviceTypeLabel: "Prestation",
        chanceAtHomeLabel: "A domicile",
        resetLabel: "Réinitialiser",
        readyLabel: "Rechercher",
        your_place_position: "Ma position",
        locate_your_location: "Pour trouver les esthéticiennes près de vous, autorisez votre géolocalisation",
        select_city: "Выбор города...FR",//----------------
        serviceSelectionLabel: "Выбор услуг...FR",
        agree_error: "Пожалуйста, нажмите...FR",//-------------
        is_deleted: "a été supprimé",
        posts_title: "Posts...FR", // meta data
        posts_descriptions: "Posts...FR",
        chats_title: "Chats...FR",
        chats_descriptions: "Chats...FR",
        help_title: "Help...FR",
        help_descriptions: "Help...FR",
        map_title: "Map...FR",
        map_descriptions: "Map...FR",
        schedule_title: "Schedule...FR",
        schedule_descriptions: "Schedule...FR",
        master_title: "My masters...FR",
        master_descriptions: "My masters...FR",
        service_title: "Service...FR",
        service_descriptions: "Service...FR",
        settings_title: "Settings...FR",
        settings_descriptions: "Settings...FR",
        masters_title: "Masters...FR",
        masters_descriptions: "Masters...FR",
        amount_title: "Amount reservation...FR",
        amount_descriptions: "Amount reservation...FR",
        register_master_title: "Register master...FR",
        register_master_descriptions: "Register master...FR",
        register_end_title: "Registration end...FR",
        register_end_descriptions: "Registration end...FR",
        order_title: "My orders...FR",
        order_descriptions: "My orders...FR",
        review_title: "Review...FR",
        review_descriptions: "Review...FR",
        add_card_title: "Add card...FR",
        add_card_descriptions: "Add card...FR",
        payment_card_title: "Payment cards...FR",
        payment_card_descriptions: "Payment cards...FR",
        operations_histroy_title: "Operations histroy...FR",
        operations_histroy_descriptions: "Operations histroy...FR",
        LOGIN_INC_US_PASS: "Identifiant ou mot de passe ne correspondent pas...FR",//errors translate ՞՞՞
        LOGIN_INACTIVE_OR_DELETED: "Login inactive or deleted...FR",//՞՞՞՞՞՞
        "username already in use": "Identifiant déjà existant",
        "user with this phone number already exists": "Ce téléphone est déjà utilisé",
        "not found": "not found...FR",
        "Not found": "Not found.FR",
        "No user with this phone number registered.": "Aucun compte n'existe avec ce numéro de téléphone",
        "No user with this email registered.": "Aucun compte n'existe avec cet e-mail",
        "No user with this email.": "Aucun compte n'existe avec cet e-mail",
        "You have ordered same service for this date.": "You have ordered same service for this date....FR",//՞՞՞
        "You have ordered same service which has no final status.": "You have ordered same service which has no final status....FR",
        "Day is non working day": "Jour est votre jour de congé",
        "No available time for this service in this day.": "Pas de créneaux disponibles pour service ce jour journée.",
        "You can not create order for this date range": "You can not create order for this date range",
        process_successful: "Le processus a réussi",
        signup: "Sign up",

    }
}
const Translate = (state = initialState, action) => {
    switch (action.type) {
        case My_personal_details.CHANGE_LANGUAGE:
            if (action.payload.code === "RU") {
                return initialState.rus
            } else if (action.payload.code === "EN") {
                return initialState.eng
            } else if (action.payload.code === "FR") {
                return initialState.frans
            }
        default: initialState.frans

    }
    return state
}
export default Translate;