import loginHook from "../../hooks/loginHook";
import { LoginRegis } from "../constants";
export const login_act = (data) => {
    return ({
        type: LoginRegis.LOGIN,
        payload: data
    })
}
export const login_loading_act = () => ({
    type: LoginRegis.LOGIN_LOADING,
})
export const login_submitted_act = () => {
    return ({
        type: LoginRegis.LOGIN_SUBMITTED,
    })
}
export const login_saga_act = (data) => {
    return ({
        type: LoginRegis.LOGIN_SAGA,
        payload: data
    })
}
export const login_saga_phone_number = (data) => {
    return ({
        type: LoginRegis.LOGIN_WITH_PHONE_NUMBER_SAGA,
        payload: data
    })
}
export const login_saga_code = (data) => {
    return ({
        type: LoginRegis.CODE_FOR_LOGIN_SAGA,
        payload: data
    })
}
export const login_page_unmount = () => {
    return ({
        type: LoginRegis.LOGIN_UMOUNT,
    })
}
export const close_alert = () => ({
    type: LoginRegis.CLOSE_ALERT,
})
export const regis_act = (data) => {        //REGISTRATION
    return ({
        type: LoginRegis.REGIS,
        payload: data
    })
}
export const regis_input_value = (data) => {
    return ({
        type: LoginRegis.SET_REGIS_INPUT_VALUE,
        payload: data
    })
}
export const show_code = () => ({
    type: LoginRegis.SHOW_CODE,
})
export const regis_loading_act = () => ({
    type: LoginRegis.REGIS_LOADING,
})
export const regis_code_loading_act = () => ({
    type: LoginRegis.REGIS_CODE_LOADING,
})
export const regis_submitted_act = () => ({
    type: LoginRegis.REGIS_SUBMITTED,
})
export const regis_code_submitted_act = () => ({
    type: LoginRegis.REGIS_CODE_SUBMITTED,
})
export const regis_saga_act = (data) => {
    return ({
        type: LoginRegis.REGIS_SAGA,
        payload: data
    })
}
export const close_regis_alert = () => ({
    type: LoginRegis.CLOSE_REGIS_ALERT,
})
export const regis_code_saga_act = (data) => ({
    type: LoginRegis.REGIS_CODE_SAGA,
    payload: data
})
export const regis_code_act = (data) => ({
    type: LoginRegis.REGIS_CODE,
    payload: data
})
export const forget_password_loading_act = () => ({ //FORGET PASSWORD
    type: LoginRegis.FORGET_PASSWORD_LOADING,
})
export const get_inputs_values = (data) => ({
    type: LoginRegis.GET_FORGET_INPUT_VALUES,
    payload: data
})
export const phone_or_email_act = (data) => ({
    type: LoginRegis.PHONE_OR_EMAIL_RES,
    payload: data
})
export const code_res_act = (data) => ({
    type: LoginRegis.CODE_RES,
    payload: data
})
export const forget_password_phone_code_saga = (data) => ({
    type: LoginRegis.FORGET_PASSWORD_PHONE_CODE_SAGA,
    payload: data
})
export const delete_code = () => ({
    type: LoginRegis.DELETE_CODE
})
export const forget_password_email_code_saga = (data) => ({
    type: LoginRegis.FORGET_PASSWORD_EMAIL_CODE_SAGA,
    payload: data
})
export const forget_password_phone_saga = (data) => ({
    type: LoginRegis.FORGET_PASSWORD_PHONE_SAGA,
    payload: data
})
export const forget_password_email_saga = (data) => ({
    type: LoginRegis.FORGET_PASSWORD_EMAIL_SAGA,
    payload: data
})
export const close_forget_alert = () => ({
    type: LoginRegis.CLOSE_FORGET_ALERT,
})
export const submitted_forget = () => ({
    type: LoginRegis.SUBMITTED_FORGET,
})
export const submitted_code_forget = () => ({
    type: LoginRegis.SUBMITTED_CODE_FORGET,
})
export const feedback_saga_act = (data) => ({            //FEEDBACK
    type: LoginRegis.FEEDBACK_SAGA,
    payload: data
})
export const feedback_loading_act = () => ({
    type: LoginRegis.FEEDBACK_LOADING,
})
export const feedback_submitted_act = () => ({
    type: LoginRegis.FEEDBACK_SUBMITTED,
})
export const feedback_act = (data) => ({
    type: LoginRegis.FEEDBACK,
    payload: data
})