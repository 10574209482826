import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { login_saga_act, close_alert, login_page_unmount } from "../store/actions/login-regis";
import { useRouter } from "next/router";

const LoginHooks = (props) => {
    const dispatch = useDispatch();
    const login = useSelector(state => state.login);
    const router = useRouter();
    const [role_code, set_role_code] = useState({ value: "CL", valid: false });
    const [username, set_username] = useState({ value: "", valid: false });
    const [password, set_password] = useState({ value: "", valid: false });
    const [loader, set_loader] = useState(false);
    const translate = useSelector(state => state.translate);
    const handleClick = (href) => {
        router.push(href);
    };
 
    const keyPress = (e) => {
        if (e.key === "Enter") {
            login_saga();
        }
    };
    const login_saga = () => {
        set_loader(true);
        return dispatch(
            login_saga_act({
                cb: () => {
                    handleClick("/");
                },
                cb_loader: () => {
                    set_loader(false);
                },
                role_code, username, password
                // role_code: role_code.value, username: username.value, password: username.value
            })
        );
    };
    const handleClose = () => dispatch(close_alert());
    useEffect(() => {
        const data = props.user;
        if (props.loggedIn) {
            router.push("/");
        }
        return () => dispatch(login_page_unmount());

    }, [props.loggedIn]);
    return {
        role_code,
        username,
        password,
        login,
        loader,
        translate,
        set_role_code,
        set_username,
        set_password,
        login_saga,
        handleClose,
        keyPress,
    };
};
export default LoginHooks;